<template>
    <div>
        <div>
            <SectionLoading v-if="loading" text="Loan Information loading..." />
            <div v-else>
                <div class="row" v-if="loanData">
                    <div class="col-md-7">
                        <div class="loan-info">
                            <div>
                                <vs-chip transparent :color="loanData.status == 'approved' ? 'success' : 'warning'">
                                    {{ `● Loan ${loanData.status}` | uppercase }}
                                </vs-chip>
                            </div>
                            <div>
                                <h1 v-if="loanData.user" class="user-info-title">{{ loanData.user.name }}</h1>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <p class="loan-text-info">
                            Applied on: <span>{{ moment(new Date(loanData.created_at)).format('MMMM DD, YYYY') }}</span>
                        </p>
                        <p class="loan-text-info">
                            Loan Duration: <span>7 Months</span>
                        </p>

                        <p class="loan-text-info">
                            Risk Score: <span>20%</span>
                        </p>
                    </div>

                </div>

                <div class="card siteCard mt-3">
                    <div class="p-3">
                        <div class="row">
                            <div class="col-md-3">
                                <div>
                                    <p class="creatorTitle">Requested Amount</p>
                                    <h3 class="my-2">
                                        <span class="text-dark font-weight-bold">{{
                                                loanData.amount | currency(getCurrencySign(loanData.user.currency),
                                                    0)
                                        }}</span>
                                    </h3>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div>
                                    <p class="creatorTitle">Payable Amount</p>
                                    <h3 class="my-2">
                                        <span class="text-dark font-weight-bold">{{
                                                loanData.payable_amount | currency(getCurrencySign(loanData.user.currency),
                                                    0)
                                        }}</span>
                                    </h3>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div>
                                    <p class="creatorTitle">Interest Rate</p>
                                    <h3 class="my-2">
                                        <span class="text-dark font-weight-bold">2.45%</span>
                                    </h3>
                                </div>
                            </div>

                            <div class="col-md-3">
                                <div>
                                    <p class="creatorTitle">Charges</p>
                                    <h3 class="my-2">
                                        <span class="text-dark font-weight-bold">{{
                                                '100' | number("0,0")
                                        }}</span>
                                    </h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3">
                        <div class="card siteCard mt-3">
                            <div class="p-3">
                                <p>Payment Remaining Chart</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="card siteCard mt-3">
                            <div class="p-3">
                                <p>Repayment Breakdown</p>
                                <p>Repayment History</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../../components/SectionLoading";
import BtnLoading from "../../../components/BtnLoading";

export default {
    name: "Home",
    components: {
        PlusIcon,
        SectionLoading, BtnLoading
    },
    data() {
        return {
            loanData: {},
            loading: true
        };
    },
    watch: {

    },
    mounted() {
        this.getLoanData();
    },
    methods: {
        getCurrencySign(currency) {
            switch (currency.toUpperCase()) {
                case "NGN":
                    return "₦";
                case "USD":
                    return "$";
                case "GBP":
                    return "£";
                case "EUR":
                    return "€";
                default:
                    return "₦";
            }
        },
        getLoanData() {
            this.loading = true;

            let fetch = {
                path: `/admin/loan/single/${this.$route.params.id}`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.loanData = resp.data?.data;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Error",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "All Users",
                            text: "Unable to get get loan information",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                }).finally(() => {
                    this.loading = false;
                })
        }
    },
};
</script>
  
<style lang="scss" scoped>
.loan-info {
    display: flex;
    flex-direction: column;
}

.user-info-title {
    font-size: 4.5rem;
    font-weight: 100;
    margin-bottom: 0;
    color: black;
    margin-top: 11px;
}

.loan-text-info {
    margin-bottom: 8px;
    font-size: 14px;

    span {
        color: black;
    }
}
</style>